import { useCallback, useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Stack, Typography } from '@mui/material'
import { trackPageView, trackSelfDescribingEvent } from '@snowplow/browser-tracker'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { InferType, object } from 'yup'

import Page from 'app/layout/Page'
import { useUserPermissions } from 'app/lib/hoc/withProtectedComponent'
import { organizationMemberList } from 'app/lib/routes'
import { getEarliestActivationDate, toISOdate, today } from 'app/lib/utils/date'
import { getSnowplowSchema } from 'app/lib/utils/helpers'
import {
  dateSchema,
  emailSchema,
  requiredDateSchema,
  requiredEmailSchema,
  requiredStringSchema,
} from 'app/lib/utils/yupSchemas'
import { EligibilityDirective } from 'app/models/EligibilityRecord'
import { getDefaultAndOtherPlans } from 'app/models/Organization'
import { MemberIdType, OrganizationRoute } from 'app/models/scribe.models'
import {
  useLazyListEligibilityRecordsQuery,
  useLegacyGetOrganizationPlansQuery,
  useLegacyGetOrganizationQuery,
  useSubmitEligibilityDirectiveMutation,
} from 'app/redux/scribeApi'

import { MemberInfo } from './MemberInfo'
import { SelectPlan } from './SelectPlan'

export enum FormViewTypes {
  GeneralInfo = 'generalInfo',
  PlanInfo = 'planInfo',
}

type MemberSchema = InferType<ReturnType<typeof memberSchema>>

const memberSchema = (isEmployeeIdOrg: boolean, activationMinDate: Date) =>
  object({
    uniqueIdentifier: isEmployeeIdOrg ? requiredStringSchema : requiredEmailSchema,
    firstName: requiredStringSchema,
    lastName: requiredStringSchema,
    dateOfBirth: isEmployeeIdOrg
      ? requiredDateSchema.max(today(), 'form.errors.invalidDateOfBirthFuture')
      : dateSchema.max(today(), 'form.errors.invalidDateOfBirthFuture'),
    province: requiredStringSchema,
    communicationEmail: emailSchema,
    activationDate: dateSchema.min(activationMinDate, 'form.errors.invalidDate'),
  })

export const OrganizationAddMemberPage = () => {
  const [formView, setFormView] = useState<string>(FormViewTypes.GeneralInfo)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { organizationId } = useParams() as OrganizationRoute
  const { data: organization, isLoading: isOrgLoading } =
    useLegacyGetOrganizationQuery(organizationId)

  const { data: plans = [], isLoading: arePlansLoading } =
    useLegacyGetOrganizationPlansQuery(organizationId)

  const [fetchEligibilityRecords] = useLazyListEligibilityRecordsQuery()
  const [submitDirective] = useSubmitEligibilityDirectiveMutation()
  const userPermissions = useUserPermissions()

  const isPageLoading = isOrgLoading || arePlansLoading

  const isGeneralInfoViewActive = formView === FormViewTypes.GeneralInfo

  const defaultValues = useMemo(() => {
    const defaultProvince = organization?.addresses[0]?.adminAreaIsoCode
    const [defaultPlan] = getDefaultAndOtherPlans(plans)
    const defaultPlanLabel = defaultPlan?.attributes.label || ''
    const organizationBillingStartDate = organization?.billingStartDate || null
    const activationMinDate = getEarliestActivationDate(
      organizationBillingStartDate,
      userPermissions,
    )

    return {
      uniqueIdentifier: '',
      firstName: '',
      lastName: '',
      dateOfBirth: null,
      province: defaultProvince || '',
      communicationEmail: '',
      activationDate: activationMinDate,
      plan: defaultPlanLabel,
    }
  }, [organization, plans, userPermissions])
  const organizationType = organization?.memberIdType
  const isEmployeeIdOrg = organizationType === MemberIdType.EMPLOYEE_ID
  const organizationBillingStartDate = organization?.billingStartDate || null

  const activationMinDate = getEarliestActivationDate(organizationBillingStartDate, userPermissions)

  const prepareDirectiveData = useCallback(
    (params: MemberSchema): EligibilityDirective => ({
      ...params,
      activationDate: params.activationDate
        ? toISOdate(params.activationDate)
        : toISOdate(defaultValues.activationDate),
      dateOfBirth: params.dateOfBirth ? toISOdate(params.dateOfBirth) : undefined,
    }),
    [defaultValues],
  )

  const formMethods = useForm<MemberSchema>({
    defaultValues,
    resolver: yupResolver(memberSchema(isEmployeeIdOrg, activationMinDate)),
  })

  const {
    handleSubmit,
    setError,
    reset,
    formState: { isDirty },
  } = formMethods

  const onSubmit = useCallback(
    (eligibilityDirective: EligibilityDirective) => {
      return submitDirective({
        organizationId,
        body: eligibilityDirective,
        params: { dryRun: false },
        extraOptions: {
          showErrorTooltip: true,
          successTooltip: 'addEditMemberDialog.success.memberAdded',
        },
      })
        .unwrap()
        .then(() => {
          navigate(organizationMemberList.get(organizationId))
          trackSelfDescribingEvent({
            event: {
              schema: getSnowplowSchema('presto_member_added', '1-0-0'),
              data: { unique_identifier: eligibilityDirective.unique_identifier },
            },
          })
        })
    },
    [organizationId, submitDirective, navigate],
  )

  const onSubmitForm = useCallback(
    (params: MemberSchema) => {
      fetchEligibilityRecords({
        params: {
          offset: 0,
          limit: 1,
          attributes: {
            uniqueIdentifier: params.uniqueIdentifier,
          },
          organizationId: [Number(organizationId)],
        },
      })
        .unwrap()
        .then((response) => {
          const isExistingRecord = response.data.length > 0
          if (isExistingRecord) {
            setError('uniqueIdentifier', {
              message: 'addEditMemberDialog.error.uniqueIdentifierDuplicate',
            })
            window.scrollTo(0, 0)
            return
          } else {
            const directive = prepareDirectiveData(params)
            onSubmit(directive)
          }
        })
    },
    [fetchEligibilityRecords, prepareDirectiveData, onSubmit, organizationId, setError],
  )

  const onCancel = () => {
    navigate(organizationMemberList.get(organizationId))
  }

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  useEffect(() => {
    trackPageView({ title: 'organization-add-member-page' })
  }, [])

  return (
    <Page isLoading={isPageLoading}>
      <Stack>
        <Typography variant="h1" data-testid="page-title">
          {isGeneralInfoViewActive
            ? t('addEditMemberDialog.title.addMember')
            : t('addEditMemberDialog.title.selectPlan')}
        </Typography>
      </Stack>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmitForm)} noValidate>
          <Stack spacing={4}>
            {isGeneralInfoViewActive ? (
              <MemberInfo organization={organization} plans={plans} onChangeView={setFormView} />
            ) : (
              <SelectPlan plans={plans} onChangeView={setFormView} />
            )}
          </Stack>
          {isGeneralInfoViewActive && (
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button variant="outlined" onClick={onCancel}>
                {t('global.dialog.cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                key="preventDoubleSubmit"
                disabled={!isDirty}
              >
                {t('global.dialog.invite')}
              </Button>
            </Stack>
          )}
        </form>
      </FormProvider>
    </Page>
  )
}
