import { useCallback, useEffect, useState } from 'react'

import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

import Page from 'app/layout/Page'
import { organizationProfile } from 'app/lib/routes'
import { OrganizationReportingColumns, OrganizationRoute } from 'app/models/scribe.models'
import {
  useLegacyGetOrganizationQuery,
  useUpdateOrganizationReportingColumnsMutation,
} from 'app/redux/scribeApi'

import { ReportingColumns } from './form/ReportingColumn'
import { ReportingColumnFormData, reportingColumnsSchema } from './form/schemas'

export const UpdateReportingColumns: React.FC = () => {
  const navigate = useNavigate()
  const { organizationId } = useParams() as OrganizationRoute

  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const { data: organization, isLoading: isOrgLoading } =
    useLegacyGetOrganizationQuery(organizationId)

  const [initialValues, setInitialValues] = useState<any>(null)
  const [updateOrganizationReportingColumns] = useUpdateOrganizationReportingColumnsMutation()

  interface AttributeSchema {
    reportingColumnOne?: string | null
    reportingColumnTwo?: string | null
    reportingColumnThree?: string | null
    reportingColumnFour?: string | null
    reportingColumnFive?: string | null
    reportingColumnSix?: string | null
    reportingColumnSeven?: string | null
  }

  useEffect(() => {
    if (organization) {
      setInitialValues({
        name: organization.name,
        attributeSchema: organization.attributeSchema,
      })
    }
  }, [organization])

  const isLoading = isOrgLoading || !initialValues
  const validationSchema = reportingColumnsSchema

  const navigateToOrganizationProfile = useCallback(() => {
    navigate(organizationProfile.get(organizationId))
  }, [navigate, organizationId])

  const handleSubmit = useCallback(
    async (formData: ReportingColumnFormData) => {
      try {
        const { attributeSchema } = formData

        const createAttribute = (key: keyof AttributeSchema) => attributeSchema?.[key] || null

        const attributes = {
          reporting_column_one: createAttribute(OrganizationReportingColumns.REPORTING_COLUMN_ONE),
          reporting_column_two: createAttribute(OrganizationReportingColumns.REPORTING_COLUMN_TWO),
          reporting_column_three: createAttribute(
            OrganizationReportingColumns.REPORTING_COLUMN_THREE,
          ),
          reporting_column_four: createAttribute(
            OrganizationReportingColumns.REPORTING_COLUMN_FOUR,
          ),
          reporting_column_five: createAttribute(
            OrganizationReportingColumns.REPORTING_COLUMN_FIVE,
          ),
          reporting_column_six: createAttribute(OrganizationReportingColumns.REPORTING_COLUMN_SIX),
          reporting_column_seven: createAttribute(
            OrganizationReportingColumns.REPORTING_COLUMN_SEVEN,
          ),
        }
        await updateOrganizationReportingColumns({
          organizationId,
          body: {
            data: {
              type: 'organization',
              attributes: {
                attribute_schema: attributes,
              },
            },
          },
        }).unwrap()
        navigateToOrganizationProfile()
        enqueueSnackbar(t('organizationPage.form.reportingColumns.successfulReportingColumns'), {
          variant: 'success',
        })
      } catch (error) {
        enqueueSnackbar(t('organizationPage.form.reportingColumns.errorReportingColumns'), {
          variant: 'error',
        })
      }
    },
    [
      organizationId,
      updateOrganizationReportingColumns,
      navigateToOrganizationProfile,
      enqueueSnackbar,
      t,
    ],
  )

  return (
    <Page isLoading={isLoading}>
      {initialValues && (
        <ReportingColumns
          onCancel={navigateToOrganizationProfile}
          onSubmit={handleSubmit}
          isLoading={isLoading}
          initialValues={initialValues}
          validationSchema={validationSchema}
          isReportingColumns
        />
      )}
    </Page>
  )
}
