import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  styled,
} from '@mui/material'

import { colors } from 'app/theme'

const EmailContainer = styled('div')({
  display: 'flex',
  padding: '20px 32px',
  alignItems: 'center',
  borderRadius: '4px',
  backgroundColor: colors.white,
  height: '56px',
  boxShadow: '0px 2px 4px 0px rgba(188, 188, 188, 0.50)',
})

type Props = {
  title: string
  subtitle: string
  email?: string
  open: boolean
  actions: React.ReactNode
  onClose: () => void
}

export const BasicActionModal: React.FC<Props> = ({
  title,
  subtitle,
  actions,
  email,
  open,
  onClose,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: { width: 760, maxWidth: 'none', backgroundColor: colors.snow },
    }}
  >
    <DialogTitle sx={{ padding: '32px', fontSize: '22px', lineHeight: '29px' }}>
      {title}
      <IconButton
        aria-label="close"
        onClick={onClose}
        color="primary"
        sx={{
          position: 'absolute',
          right: 32,
          p: 0,
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent sx={{ padding: '32px' }}>
      <Typography variant="subtitle2" sx={{ mb: 1.5, fontWeight: 400 }}>
        {subtitle}
      </Typography>
      <EmailContainer>
        <Typography variant="subtitle2">{email}</Typography>
      </EmailContainer>
    </DialogContent>
    <DialogActions sx={{ p: '0px 32px 32px' }}>{actions}</DialogActions>
  </Dialog>
)
