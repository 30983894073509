import { useCallback, useEffect, useMemo } from 'react'

import { trackPageView, trackSelfDescribingEvent } from '@snowplow/browser-tracker'
import { useNavigate, useParams } from 'react-router'

import Page from 'app/layout/Page'
import { DEFAULT_INVITATION_TIME, ScribePermission } from 'app/lib/constants'
import { useUserPermissions } from 'app/lib/hoc/withProtectedComponent'
import { organizationProfile } from 'app/lib/routes'
import { fromISOdate, todayWithTime } from 'app/lib/utils/date'
import { getSnowplowSchema } from 'app/lib/utils/helpers'
import { generateOrganizationAttributes } from 'app/lib/utils/organization'
import { Address, BrandIDEnum, OrganizationRoute } from 'app/models/scribe.models'
import {
  useLegacyGetOrganizationQuery,
  useUpdateOrganizationAddressMutation,
  useUpdateOrganizationMutation,
} from 'app/redux/scribeApi'

import { OrganizationForm } from './form/OrganizationForm'
import {
  EditFullOrganizationFormData,
  EditRestrictedOrganizationFormData,
  editFullOrganizationSchema,
  editRestrictedOrganizationSchema,
} from './form/schemas'

const adminNonEditableFields = [
  'memberIdType',
  'streetAddress',
  'suite',
  'city',
  'province',
  'postalCode',
  'billingStartDate',
  'clientNotes',
  'careTeamNotes',
  'billingMethod',
  'forcedMfa',
  'reportsEnabled',
  'enrolmentCode',
  'communicationsAllowed',
  'invitationEmailTime',
]

export const EditOrganizationPage: React.FC = () => {
  const navigate = useNavigate()
  const { organizationId } = useParams() as OrganizationRoute

  const userPermissions = useUserPermissions()
  const canCreateOrg = userPermissions?.includes(ScribePermission.CREATE_ORGANIZATION)
  const canEditOrgName = userPermissions?.includes(ScribePermission.UPDATE_ORGANIZATION_NAME)
  const canUpdateBilling = userPermissions?.includes(ScribePermission.UPDATE_ORGANIZATION_BILLING)

  const { data: organization, isLoading: isOrgLoading } =
    useLegacyGetOrganizationQuery(organizationId)

  const [updateOrganization, { isLoading: isUpdating }] = useUpdateOrganizationMutation()
  const [updateAddress, { isLoading: isAddressUpdating }] = useUpdateOrganizationAddressMutation()

  const isLoading = isOrgLoading || isUpdating || isAddressUpdating || !userPermissions

  const address = organization?.addresses?.[0]

  const validationSchema = canUpdateBilling
    ? editFullOrganizationSchema
    : editRestrictedOrganizationSchema

  const invitationEmailTime = organization?.invitationEmailTime || DEFAULT_INVITATION_TIME

  const baseInitialValues = {
    name: organization?.name,
    displayNameEnglish: organization?.displayName.en,
    displayNameFrench: organization?.displayName.fr,
    memberIdType: organization?.memberIdType,
    emailPreference: organization?.emailPreference,
    clientNotes: organization?.clientNotes || '',
    careTeamNotes: organization?.careTeamNotes || '',
    forcedMfa: !!organization?.forcedMfa,
    reportsEnabled: organization?.reportsEnabled,
    communicationsAllowed: organization?.communicationsAllowed,
    invitationEmailTime: todayWithTime(invitationEmailTime),
    streetAddress: address?.streetAddress || '',
    suite: address?.suite || '',
    city: address?.city || '',
    brandId: organization?.brand?.data?.id || BrandIDEnum.Dia,
    province: address?.adminAreaIsoCode || '',
    postalCode: address?.postalCode || '',
    enrolmentCode: organization?.meta?.organizationEnrolmentCode || '',
    billingEmail: organization?.billingEmail || '',
    billingMethod: organization?.billingMethod || '',
    billingStartDate: organization?.billingStartDate
      ? fromISOdate(organization?.billingStartDate)
      : undefined,
  }

  const initialValues = validationSchema.cast(baseInitialValues, {
    assert: 'ignore-optionality',
  })

  const nonEditableFields = useMemo(() => {
    const fields = canCreateOrg ? [] : adminNonEditableFields
    if (!canEditOrgName) {
      fields.push('name')
    }
    return fields
  }, [canCreateOrg, canEditOrgName])

  const navigateToOrganizationProfile = useCallback(() => {
    navigate(organizationProfile.get(organizationId))
  }, [navigate, organizationId])

  useEffect(() => {
    trackPageView({ title: 'organization-edit-page' })
  }, [])

  const handleSubmit = useCallback(
    async (formData: EditFullOrganizationFormData | EditRestrictedOrganizationFormData) => {
      try {
        const addressId = address?.id
        const {
          streetAddress,
          suite,
          postalCode,
          city,
          province,
          brandId,
          ...organizationFormData
        } = formData
        const shouldUpdateOrg = !!Object.keys(organizationFormData).length || province
        const addressAttributes: Partial<Address> = {
          streetAddress,
          suite,
          postalCode,
          city,
          adminAreaIsoCode: province,
        }

        const addressBodyKeys = Object.keys(addressAttributes) as (keyof Address)[]
        const updateAddressBody = addressBodyKeys.reduce((acc, curr) => {
          const attr = addressAttributes[curr]
          return attr === undefined || attr === address?.[curr] ? acc : { ...acc, [curr]: attr }
        }, {})

        const shouldUpdateAddress = addressId && !!Object.keys(updateAddressBody).length

        await Promise.all([
          shouldUpdateOrg &&
            updateOrganization({
              organizationId,
              body: {
                data: {
                  type: 'organization',
                  attributes: generateOrganizationAttributes(
                    organizationFormData,
                    true,
                    canUpdateBilling === false,
                  ),
                  relationships: { brand: { data: { id: brandId, type: 'brand' } } },
                },
              },
            }),
          shouldUpdateAddress &&
            updateAddress({
              addressId,
              organizationId: organizationId,
              body: {
                data: {
                  type: 'organization_address',
                  attributes: updateAddressBody,
                },
              },
            }),
        ]).then(navigateToOrganizationProfile)
      } finally {
        trackSelfDescribingEvent({
          event: {
            schema: getSnowplowSchema('button_click', '1-0-0'),
            data: {
              button_value: `Submit Edit Organization Form on Presto`,
            },
          },
        })
      }
    },
    [
      updateOrganization,
      navigateToOrganizationProfile,
      updateAddress,
      address,
      organizationId,
      canUpdateBilling,
    ],
  )

  return (
    <Page isLoading={isLoading}>
      {organization && (
        <OrganizationForm
          onCancel={navigateToOrganizationProfile}
          onSubmit={handleSubmit}
          isLoading={isLoading}
          initialValues={initialValues}
          validationSchema={validationSchema}
          nonEditableFields={nonEditableFields}
          isEdit
        />
      )}
    </Page>
  )
}
