import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { Box, Button, Paper, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { colors } from 'app/theme'

interface ConnectionErrorProps {
  onLogin: () => void
}
const ConnectionError = ({ onLogin }: ConnectionErrorProps) => {
  const { t } = useTranslation()

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor={colors.dialogueLinen}
      height="100%"
      width="100%"
    >
      <Paper elevation={4}>
        <Stack display="flex" justifyContent="center" sx={{ p: 6 }}>
          <ErrorOutlineOutlinedIcon
            sx={{ fontSize: 50, color: colors.alarm, alignSelf: 'center' }}
          />
          <Typography variant="h1" textAlign="center" mt={2}>
            {t('loginConnectionPage.error.title')}
          </Typography>
          <Typography variant="body2" textAlign="center" mt={1} mb={3} whiteSpace="break-spaces">
            {t('loginConnectionPage.error.description')}
          </Typography>
          <Button variant="contained" sx={{ alignSelf: 'center' }} onClick={onLogin}>
            {t('global.retry')}
          </Button>
        </Stack>
      </Paper>
    </Box>
  )
}

export default ConnectionError
