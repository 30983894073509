import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { useResetUserPasswordMutation } from 'app/redux/multipassApi'

import { BasicActionModal } from './BasicActionModal'

type Props = {
  open: boolean
  onClose: () => void
  authId: string
  email?: string
}

export const ResetPassword: React.FC<Props> = ({ open, onClose, authId, email }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [resetUserPassword, { isLoading }] = useResetUserPasswordMutation()

  const handleResetPassword = async () => {
    try {
      await resetUserPassword({ authId }).unwrap()
      enqueueSnackbar(t('userProfile.actions.successfulResetPassword'), {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar(t('userProfile.actions.errorResetPassword'), {
        variant: 'error',
      })
    }
    onClose()
  }

  return (
    <BasicActionModal
      open={open}
      onClose={onClose}
      title={t('userProfile.actions.resetPassword')}
      subtitle={t('userProfile.info.resetEmailConfirmation')}
      email={email}
      actions={
        <>
          <Button variant="outlined" onClick={onClose} data-testid="close-button">
            {t('global.dialog.cancel')}
          </Button>
          <LoadingButton
            color="error"
            variant="contained"
            loading={isLoading}
            onClick={handleResetPassword}
            type="submit"
            sx={{ ml: 2 }}
            data-testid="reset-button"
          >
            {t('userProfile.actions.resetPassword')}
          </LoadingButton>
        </>
      }
    />
  )
}
