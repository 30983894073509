import { ChangeEvent, useState } from 'react'

import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import InputFormField from 'app/lib/components/form/InputFormField'
import SwitchFormField from 'app/lib/components/form/SwitchFormField'
import TimePickerFormField from 'app/lib/components/form/TimePickerFormField'
import { TIME_FORMATS } from 'app/lib/constants'

const enum AttributeNames {
  enrolmentCode = 'enrolmentCode',
  communicationsAllowed = 'communicationsAllowed',
  invitationEmailTime = 'invitationEmailTime',
}

export const Attributes: React.FC<{ nonEditableFields: string[] }> = ({ nonEditableFields }) => {
  const { t } = useTranslation()
  const { setValue, watch } = useFormContext()

  const enrolmentCode = watch(AttributeNames.enrolmentCode)
  const communicationsAllowed = watch(AttributeNames.communicationsAllowed)

  const [preservedCode, setPreservedCode] = useState<string>(enrolmentCode)
  const [isCodeSwitchActive, setCodeSwitchActive] = useState<boolean>(!!enrolmentCode)

  const handleCodeSwitchChange = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (!checked && enrolmentCode) {
      if (preservedCode !== enrolmentCode) {
        setPreservedCode(enrolmentCode)
      }
      setValue(AttributeNames.enrolmentCode, '', { shouldDirty: true })
    }
    if (checked && preservedCode) {
      setValue(AttributeNames.enrolmentCode, preservedCode, { shouldDirty: true })
    }
    setCodeSwitchActive(checked)
  }

  return (
    <Card>
      <CardContent sx={{ p: 4 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2" mb={2}>
              {t('organizationPage.form.attributes.title')}
            </Typography>
          </Grid>
          <Grid container item xs={12} md={6} spacing={2}>
            <Grid item xs={12} display="flex" alignItems="center" height={60}>
              <SwitchFormField
                name="forcedMfa"
                label={t('organizationPage.form.attributes.forcedMfa')}
                disabled={nonEditableFields.includes('forcedMfa')}
                defaultChecked
                color="warning"
              />
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center" height={60}>
              <SwitchFormField
                name="reportsEnabled"
                label={t('organizationPage.form.attributes.reportsEnabled')}
                disabled={nonEditableFields.includes('reportsEnabled')}
                color="warning"
              />
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center" height={60}>
              <SwitchFormField
                name="communicationsAllowed"
                label={t('organizationPage.form.attributes.communicationsAllowed')}
                color="warning"
                disabled={nonEditableFields.includes(AttributeNames.communicationsAllowed)}
              />
            </Grid>
            {communicationsAllowed && (
              <Grid item xs={12} md={6} display="flex" alignItems="start" flexDirection="column">
                <Typography variant="caption2">
                  {t('organizationPage.form.attributes.communicationsTime')}
                </Typography>
                <TimePickerFormField
                  name="invitationEmailTime"
                  format={`${TIME_FORMATS.INVTITATION_TIME} UTC`}
                  variant="outlined"
                  disabled={nonEditableFields.includes(AttributeNames.invitationEmailTime)}
                />
                <Button
                  variant="link"
                  target="_blank"
                  href="https://dateful.com/convert/est-edt-eastern-time?t=1334&tz2=Coordinated-Universal-Time-UTC"
                  sx={{ px: 0 }}
                >
                  {t('organizationPage.form.attributes.utcConverterLinkLabel')}
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid container item xs={12} md={6} spacing={2}>
            <Grid item xs={12} md={4} display="flex" alignItems="center" height={60}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={handleCodeSwitchChange}
                    checked={isCodeSwitchActive}
                    inputProps={
                      {
                        'data-testid': 'enrolment-code-enabled-switch',
                      } as React.InputHTMLAttributes<HTMLInputElement>
                    }
                  />
                }
                disabled={nonEditableFields.includes(AttributeNames.enrolmentCode)}
                label={t('organizationPage.form.attributes.enrolmentCodeEnabled')}
              />
            </Grid>
            {isCodeSwitchActive && (
              <Grid item xs={12} md={6}>
                <InputFormField
                  name="enrolmentCode"
                  disabled={nonEditableFields.includes(AttributeNames.enrolmentCode)}
                  variant="outlined"
                  hideLabel
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
