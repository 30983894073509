import { REGIONS } from 'app/lib/constants'

import { developmentConfig } from './development.config'
import { productionConfig } from './production.config'
import { productionEuConfig } from './production.eu.config'
import { productionUsConfig } from './production.us.config'
import { testConfig } from './test.config'

export type Config = Readonly<{
  auth0: {
    audience: string
    clientId: string
    domain: string
    connections: Record<string, string>
  }
  launchDarkly: {
    clientId: string
  }
  intercom: {
    appId: string
  }
  scribe: {
    baseUrl: string
  }
  multipass: {
    baseUrl: string
  }
  coredata: {
    baseUrl: string
  }
  activeMinutes: {
    baseUrl: string
  }
  stripePublicKey: string
  theme: {
    applicationBar: {
      backgroundColor: string
    }
  }
  assets_url: string
  snowplow: {
    appId: string
    namespace: string
    endpoint: string
  }
  region: REGIONS
}>

type ReactApplicationEnvironment = 'local' | 'test' | 'dev-ca' | 'prod-ca' | 'prod-eu' | 'prod-us'

const configs: Record<ReactApplicationEnvironment, Config> = {
  local: developmentConfig,
  test: testConfig,
  'dev-ca': developmentConfig,
  'prod-ca': productionConfig,
  'prod-eu': productionEuConfig,
  'prod-us': productionUsConfig,
}

export function getApplicationConfig(): Config {
  return configs[process.env.VITE_APP_ENV as ReactApplicationEnvironment]
}

export function getApplicationEnvironment() {
  return process.env.VITE_APP_ENV as ReactApplicationEnvironment
}
