import { memo } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Card, CardContent, Stack, Typography } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import isMobilePhone from 'validator/es/lib/isMobilePhone'
import { InferType, object, string } from 'yup'

import { FormRow } from 'app/lib/components/form/FormRow'
import InputFormField from 'app/lib/components/form/InputFormField'
import { requiredEmailSchema, requiredStringSchema } from 'app/lib/utils/yupSchemas'

const adminSchema = object({
  email: requiredEmailSchema,
  firstName: requiredStringSchema,
  lastName: requiredStringSchema,
  phoneNumber: string().test('is-mobile-phone', 'global.invalidPhoneNumber', (val) => {
    if (!val) return true
    return isMobilePhone(val, 'en-CA')
  }),
})

export type AdminFormSchema = InferType<typeof adminSchema>

const defaultValues: AdminFormSchema = {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
}

const formFields = Object.keys(defaultValues) as (keyof AdminFormSchema)[]

interface AdminFormProps {
  onCancel: () => void
  onSubmit: (values: AdminFormSchema) => void
  initialValues?: AdminFormSchema
}

export const AdminForm: React.FC<AdminFormProps> = memo(
  ({ initialValues = defaultValues, onSubmit, onCancel }) => {
    const formMethods = useForm<AdminFormSchema>({
      defaultValues: { ...defaultValues, ...initialValues },
      resolver: yupResolver(adminSchema),
    })

    const {
      formState: { isDirty },
      handleSubmit,
    } = formMethods

    const { t } = useTranslation()

    return (
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mb={2.5}
              py={2}
            >
              <Typography variant="h2" data-testid="page-title">
                {t('adminPage.title.new')}
              </Typography>
              <Stack direction="row" justifyItems="flex-end" spacing={2}>
                <Button variant="outlined" onClick={onCancel}>
                  {t('global.dialog.cancel')}
                </Button>
                <Button
                  type="submit"
                  key="preventDoubleSubmit"
                  variant="contained"
                  color="primary"
                  disabled={!isDirty}
                >
                  {t('actions.save')}
                </Button>
              </Stack>
            </Stack>
            <Card>
              <CardContent>
                {formFields.map((name: keyof AdminFormSchema) => (
                  <FormRow
                    key={name}
                    label={t(`organizationProfileAdmins.${name}`)}
                    field={
                      <InputFormField
                        name={name}
                        label={t(`organizationProfileAdmins.${name}`)}
                        variant="outlined"
                        hideLabel
                      />
                    }
                  />
                ))}
              </CardContent>
            </Card>
          </Stack>
        </form>
      </FormProvider>
    )
  },
)
