import {
  format,
  getDaysInMonth,
  isAfter,
  isSameDay,
  max,
  parseISO,
  startOfDay,
  subDays,
  subYears,
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import { i18n } from 'app/i18n'
import { ScribePermission } from 'app/lib/constants'

type DateStyle = Intl.DateTimeFormatOptions['dateStyle']

export type Infinity = 'infinity'

export type DateString = `${string} ${number}, ${number}`

const stripDate = (dateISO: string): string => dateISO.split('T')[0]

//ISO strings are comparable
export const isInfinity = (dateISO: string): boolean => dateISO >= '9999-12-31'

export const toISOdate = (datetime: Date): string => format(datetime, 'yyyy-MM-dd')

export const toISOdatetime = (datetime: Date): string => format(datetime, 'yyyyMMddHHmmss')

export const fromISOdate = (isoDate: string): Date => parseISO(stripDate(isoDate))

export const today = (): Date => utcToZonedTime(new Date(), 'UTC')

export const yesterday = (): Date => subDays(today(), 1)

export const isSameDayOrLater = (startDate: Date, nextDay: Date): boolean =>
  isAfter(startDate, nextDay) || isSameDay(startDate, nextDay)

export const minChildDateOfBirth = (): Date => subYears(today(), 14)

export const getMonth = (datetime: Date, locale: string): string =>
  Intl.DateTimeFormat(locale, { month: 'long' }).format(datetime)

export const formatDate = (
  date: string | Date,
  locale: string,
  dateStyle: DateStyle = 'medium',
): string => {
  try {
    const isoString = typeof date === 'string' ? date : toISOdate(date)
    if (isInfinity(isoString)) return i18n.t('global.infinity')
    return Intl.DateTimeFormat(locale, { dateStyle }).format(fromISOdate(isoString))
  } catch {
    return i18n.t('global.invalidDate')
  }
}

export const isInvalidBirthdate = (date: Date) => date > today()

export const isValidDate = (date: Date) => {
  try {
    toISOdate(date)
    return true
  } catch {
    return false
  }
}

export const getEarliestActivationDate = (
  billingStartDate: string | null,
  userPermissions?: string[] | undefined,
): Date => {
  const isOrganizationManager = userPermissions?.includes(ScribePermission.MANAGE_ORGANIZATIONS)

  let date

  if (!billingStartDate || isOrganizationManager) date = today()
  else date = max([fromISOdate(billingStartDate), today()])

  return startOfDay(date)
}

export const daysInMonth = (date: string): number => getDaysInMonth(fromISOdate(date))

export const todayWithTime = (time: string): Date => {
  const [hours, minutes, seconds] = time.split(':').map(Number)
  return new Date(new Date().setHours(hours, minutes, seconds))
}
