import React from 'react'

import './app/i18n'
import './assets/fonts/index.css'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { newTracker } from '@snowplow/browser-tracker'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'

import { App } from 'app/App'
import { AuthenticationProvider } from 'app/lib/auth/AuthenticationProvider'
import { LaunchDarklyProvider } from 'app/lib/launchdarkly/LaunchDarklyProvider'
import { store } from 'app/redux/store'
import { getApplicationConfig } from 'config'

// We didn't write this code, so ignoring eslint and TS.
/* eslint-disable */
const branch = () => {
  var href = location.href
  var isBranch = href.indexOf('/branches/') !== -1
  var isRoot = location.pathname === '/' || location.pathname === ''
  var baseHref = isBranch ? href : isRoot ? '/' : href.replace(location.pathname, '')
  var baseElement = document.createElement('base')
  baseElement.setAttribute('href', baseHref)
  document.head.appendChild(baseElement)
}
branch()

// <!-- Sprig integration -->
const sprig = () => {
  var environment_id = ''

  // Prod environments
  if (process.env.VITE_APP_ENV?.includes('prod')) {
    environment_id = '-EyypbObS8uN'
  }

  // Dev environment
  if (process.env.VITE_APP_ENV?.includes('dev')) {
    environment_id = '1K3UO210snDQ'
  }

  if (environment_id) {
    ;(function (l, e, a, p) {
      // @ts-ignore
      if (window.Sprig) return
      window.Sprig = function () {
        // @ts-ignore
        S._queue.push(arguments)
      }
      var S = window.Sprig
      // @ts-ignore
      S.appId = a
      // @ts-ignore
      S._queue = []
      // @ts-ignore
      window.UserLeap = S
      // @ts-ignore
      a = l.createElement('script')
      // @ts-ignore
      a.async = 1
      // @ts-ignore
      a.src = e + '?id=' + S.appId
      // @ts-ignore
      p = l.getElementsByTagName('script')[0]
      // @ts-ignore
      p.parentNode.insertBefore(a, p)
    })(document, 'https://cdn.sprig.com/shim.js', environment_id)
  }
}
sprig()
/* eslint-enable */

const config = getApplicationConfig()
const stripePromise = loadStripe(config.stripePublicKey)

newTracker(config.snowplow.namespace, config.snowplow.endpoint, {
  appId: config.snowplow.appId,
})

// Mocks API in TEST environment only (for Storybook)
if (process.env.VITE_APP_ENV === 'test') {
  const { worker } = require('./mocks/browser')
  worker.start()
}

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <AuthenticationProvider>
          <LaunchDarklyProvider>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <Elements stripe={stripePromise}>
                <App />
              </Elements>
            </LocalizationProvider>
          </LaunchDarklyProvider>
        </AuthenticationProvider>
      </HashRouter>
    </Provider>
  </React.StrictMode>,
)
