import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { camelize, camelizeKeys, decamelizeKeys } from 'humps'
import { enqueueSnackbar } from 'notistack'

import { i18n } from 'app/i18n'
import { tokenProvider } from 'app/lib/auth/tokenProvider'
import { FetchBaseQueryErrorStatuses } from 'app/lib/constants'
import { CustomBaseQueryType, ErrorResponse, isErrorResponseV1 } from 'app/models/api'
import { getApplicationConfig } from 'config'

const { t } = i18n
const config = getApplicationConfig()

const {
  scribe: { baseUrl },
} = config

const baseQueryWithHeader = retry(
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers: Headers, api: any) => {
      const { endpoint } = api

      const token = await tokenProvider.getAccessTokenSilently()()
      headers.set('Authorization', `Bearer ${token}`)

      const UPLOAD_ENDPOINTS = ['addActionRequest']

      if (!UPLOAD_ENDPOINTS.includes(endpoint)) {
        headers.set('content-type', 'application/json')
      }

      return headers
    },
    paramsSerializer: (params: Record<string, any>): string => {
      let query = new URLSearchParams()

      Object.entries(params).forEach(([k, v]) => {
        if (Array.isArray(v)) {
          // Turn array into repeating keys
          v.forEach((vv) => query.append(k, vv))
        } else if (v != null) {
          // Ignore any null or undefined values
          query.append(k, v)
        }
      })
      return query.toString()
    },
  }),
)

const getErrorMessageFromStatusCode = (error: ErrorResponse, extraOptions: any): string => {
  const detail = isErrorResponseV1(error.data) ? error.data.errors[0].detail : error.data.detail
  const errorDetail = typeof detail === 'string' ? detail : detail?.[0]?.msg
  const translatedMessage = t(`${extraOptions?.errorCodes}.${error.status}`, {
    defaultValue: '',
    returnObjects: true,
  })

  if (typeof translatedMessage == 'string') {
    return translatedMessage || errorDetail
  }

  return errorDetail
}

const getErrorMessageFromValidationError = (error: ErrorResponse, extraOptions: any): string => {
  const errorCodes = extraOptions?.errorCodes
  let errorKeys: string[] = []

  if (isErrorResponseV1(error.data)) {
    const detailV1 = error.data.errors[0]
    const codeNormalized = camelize(detailV1.code.replaceAll('.', '_') || '')
    errorKeys = [`${errorCodes}.${error.status}.${codeNormalized}`]
  } else if (!Array.isArray(error.data.detail)) {
    return getErrorMessageFromStatusCode(error, extraOptions)
  } else {
    const detailV2 = error.data.detail[0]
    const typeNormalized = camelize(detailV2.type?.replaceAll('.', '_') || '')
    const locNormalized = camelize(detailV2.loc?.join('_') || '')
    errorKeys = [
      `${errorCodes}.${error.status}.${typeNormalized}.${locNormalized}`,
      `${errorCodes}.${error.status}.${typeNormalized}`,
    ]
  }

  const errorDetailMessage = t(errorKeys, { returnObjects: true, defaultValue: '' })
  const isErrorMessageFound = typeof errorDetailMessage == 'string' && errorDetailMessage.length > 0

  return isErrorMessageFound
    ? errorDetailMessage
    : getErrorMessageFromStatusCode(error, extraOptions)
}

export const getErrorMessageFromResponse = (error: ErrorResponse, extraOptions: any): string => {
  if (error.status === 422) {
    return getErrorMessageFromValidationError(error, extraOptions) || t('global.clientError')
  } else if (error.status < 500) {
    return getErrorMessageFromStatusCode(error, extraOptions) || t('global.clientError')
  } else {
    return getErrorMessageFromStatusCode(error, extraOptions) || t('global.unknownError')
  }
}

const customBaseQuery: CustomBaseQueryType = async (args, api, extraOptions = {}) => {
  if (typeof args === 'object') {
    args.params = decamelizeKeys(args.params)
    if (!(args.body instanceof FormData)) {
      args.body = decamelizeKeys(args.body)
    }
    extraOptions = { ...extraOptions, ...(args.extraOptions || {}) }
  }

  let response = await baseQueryWithHeader(args, api, {
    ...extraOptions,
    retryCondition: (error, _, retryProps) => {
      const shouldRetry = retryProps.attempt < 5
      switch (error.status) {
        case FetchBaseQueryErrorStatuses.PARSING_ERROR:
          return shouldRetry && error.originalStatus >= 500
        case FetchBaseQueryErrorStatuses.TIMEOUT_ERROR:
          return shouldRetry
        default:
          return shouldRetry && (error.status as number) >= 500
      }
    },
  })

  if (extraOptions?.showErrorTooltip) {
    let error = response.error as ErrorResponse
    if (!error) {
      const message: string =
        t(`${extraOptions?.successTooltip}`, { defaultValue: '' }) || 'Success'
      enqueueSnackbar(message, { variant: 'success' })
    } else {
      const message = getErrorMessageFromResponse(error, extraOptions)
      enqueueSnackbar(message, { variant: error.status < 500 ? 'warning' : 'error' })
    }
  }

  return camelizeKeys(response) as { data: any; meta?: any }
}

export default customBaseQuery
