import { useCallback, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonProps, Card, Grid, Typography } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InferType, object } from 'yup'

import InputFormField, { InputFormFieldProps } from 'app/lib/components/form/InputFormField'
import { LoadingBackdrop } from 'app/lib/components/LoadingBackdrop'
import { ScribePermission } from 'app/lib/constants'
import withEditModeField from 'app/lib/hoc/withEditModeField'
import withProtectedComponent from 'app/lib/hoc/withProtectedComponent'
import { emailSchema } from 'app/lib/utils/yupSchemas'
import { useUpdateUserEmailMutation } from 'app/redux/multipassApi'

import { ResendVerification } from './ActionModals/ResendVerification'
import { ResetPassword } from './ActionModals/ResetPassword'

type Props = {
  userId: string
  authId: string
  email?: string
}

const emailFormSchema = object({
  email: emailSchema,
})

type EmailFormSchema = InferType<typeof emailFormSchema>

const EditableField = withEditModeField<InputFormFieldProps>(InputFormField)

const ProtectedButton = withProtectedComponent<ButtonProps>(Button)

enum ActionModals {
  RESET_PASSWORD = 'RESET_PASSWORD',
  RESEND_VERIFICATION = 'RESEND_VERIFICATION',
}

export const UserEmail: React.FC<Props> = ({ userId, authId, email }) => {
  const { t } = useTranslation()

  const [isEditMode, setEditMode] = useState<boolean>(false)

  const [activeModal, setActiveModal] = useState<ActionModals | null>(null)

  const [updateUserEmail, { isLoading }] = useUpdateUserEmailMutation()

  const formMethods = useForm<EmailFormSchema>({
    defaultValues: { email },
    resolver: yupResolver(emailFormSchema),
  })

  const handleActionModal = useCallback(
    (modal: ActionModals | null) => () => {
      setActiveModal(modal)
    },
    [],
  )

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formMethods

  const onSubmit = (values: EmailFormSchema) => {
    if (!values.email) {
      return
    }

    updateUserEmail({ userId, authId, body: { email: values.email } })
      .unwrap()
      .then(toggleEditState)
  }

  const toggleEditState = () => setEditMode((state) => !state)

  const handleCancel = () => {
    reset()
    toggleEditState()
  }

  return (
    <>
      <ResetPassword
        open={activeModal === ActionModals.RESET_PASSWORD}
        onClose={handleActionModal(null)}
        authId={authId}
        email={email}
      />
      <ResendVerification
        open={activeModal === ActionModals.RESEND_VERIFICATION}
        onClose={handleActionModal(null)}
        authId={authId}
        email={email}
      />
      <Card sx={{ p: 4, mt: 2 }}>
        <LoadingBackdrop loading={isLoading} />
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3} lg={2}>
                <Typography variant="subtitle2">{t('userProfile.info.loginEmail')}</Typography>
              </Grid>
              <Grid item xs={6} lg={4}>
                <EditableField
                  name="email"
                  label={t('userProfile.info.loginEmail')}
                  variant="outlined"
                  hideLabel
                  isEditMode={isEditMode}
                />
              </Grid>
              <Grid item xs={3} lg={6} display="flex" justifyContent="flex-end">
                {isEditMode ? (
                  <>
                    <Button variant="outlined" onClick={handleCancel}>
                      {t('global.dialog.cancel')}
                    </Button>
                    <Button variant="contained" type="submit" disabled={!isDirty} sx={{ ml: 2 }}>
                      {t('actions.save')}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      disabled={!authId}
                      variant="outlined"
                      onClick={handleActionModal(ActionModals.RESET_PASSWORD)}
                    >
                      {t('userProfile.actions.resetPassword')}
                    </Button>
                    <ProtectedButton
                      permission={ScribePermission.VERIFY_EMAIL}
                      disabled={!authId}
                      variant="outlined"
                      onClick={handleActionModal(ActionModals.RESEND_VERIFICATION)}
                      sx={{ ml: 2 }}
                    >
                      {t('userProfile.actions.resendVerification')}
                    </ProtectedButton>
                    <ProtectedButton
                      disabled={!authId} // email change only works for signed-up Auth0 users
                      variant="outlined"
                      permission={ScribePermission.UPDATE_USER_EMAIL}
                      onClick={toggleEditState}
                      sx={{ ml: 2 }}
                    >
                      {t('userProfile.actions.editEmail')}
                    </ProtectedButton>
                  </>
                )}
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Card>
    </>
  )
}
