import { useEffect, useMemo, useState } from 'react'

import { trackPageView } from '@snowplow/browser-tracker'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import Page from 'app/layout/Page'
import { EligibleIntervals } from 'app/lib/components/EligibleIntervals'
import { NoResults } from 'app/lib/components/NoResults'
import { ScribePermission } from 'app/lib/constants'
import { useUserPermissions } from 'app/lib/hoc/withProtectedComponent'
import { getCurrentAndOtherPlans } from 'app/models/Organization'
import { EligibilityRecordRoute, EligibilityRecordStatus } from 'app/models/scribe.models'
import {
  useGetEligibilityRecordQuery,
  useLegacyGetOrganizationPlansQuery,
  useLegacyGetOrganizationQuery,
  useSubmitEligibilityDirectiveMutation,
} from 'app/redux/scribeApi'
import { NoSearchResults } from 'assets/images'

import { PlanDetails } from './PlanDetails'
import { Profile } from './Profile'
import { UserAccount } from './UserAccount'

export const DEFAULT_LIMIT = 5

export const OrganizationMemberPage = () => {
  const { t } = useTranslation()
  const { organizationId, eligibilityRecordId } = useParams() as EligibilityRecordRoute
  const userPermissions = useUserPermissions()
  const canSeeUserAccountDetails = userPermissions?.includes(ScribePermission.LIST_USERS)
  const canSeeEligibleIntervals = userPermissions?.includes(ScribePermission.MANAGE_ORGANIZATIONS)
  const { data: organization } = useLegacyGetOrganizationQuery(organizationId)
  const {
    data: eligibilityRecord,
    isFetching,
    isError,
  } = useGetEligibilityRecordQuery(eligibilityRecordId)

  const [eligibleIntervalsPagination, setEligibleIntervalsPagination] = useState({
    offset: 0,
    limit: DEFAULT_LIMIT,
    total: 0,
  })
  const [, { isLoading: isUpdating }] = useSubmitEligibilityDirectiveMutation({
    fixedCacheKey: 'eligibilityRecordUpdate',
  })
  const { data: plans } = useLegacyGetOrganizationPlansQuery(organizationId)

  useEffect(() => {
    trackPageView({ title: 'eligibility-record' })
  }, [])

  const { 0: currentPlan } = useMemo(
    () => getCurrentAndOtherPlans(plans, eligibilityRecord),
    [plans, eligibilityRecord],
  )

  const errorProp = isError && (
    <NoResults
      title={t('eligibilityRecordPage.errors.notFound')}
      img={<NoSearchResults title="no_results" />}
    />
  )

  return (
    <Page isLoading={isFetching || isUpdating} error={errorProp}>
      {eligibilityRecord && organization?.memberIdType && (
        <Profile
          eligibilityRecord={eligibilityRecord}
          organizationType={organization.memberIdType}
          organisationName={organization.name}
        />
      )}
      {currentPlan && (
        <PlanDetails
          plan={currentPlan}
          editable={eligibilityRecord?.status === EligibilityRecordStatus.ACTIVE}
        />
      )}
      {canSeeUserAccountDetails && (
        <UserAccount userAccountDetails={eligibilityRecord?.participant} />
      )}
      {canSeeEligibleIntervals && (
        <EligibleIntervals
          pagination={eligibleIntervalsPagination}
          setPagination={setEligibleIntervalsPagination}
          eligibleIntervals={eligibilityRecord?.eligibleIntervals || []}
        />
      )}
    </Page>
  )
}
